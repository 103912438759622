import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {
  EsraPipingAPIClient,
  PressureTemperatureTableViewModel,
  PressureTemperatureCellViewModel,
  CommonNoteViewModel,
  PressureTemperatureTableNote,
  NoteType,
} from 'src/app/shared/models/autogenerated-piping';
import {
  faPlusCircle,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { uniqueFilter } from 'src/app/shared/utils';
import { Constants } from 'src/app/shared/constants/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pressure-temp-edit',
  templateUrl: './pressure-temp-edit.component.html',
  styleUrls: ['./pressure-temp-edit.component.scss'],
})
export class PressureTempEditComponent implements OnInit {
  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  pressureTemperatureForm: FormGroup;

  faPlusCircle = faPlusCircle;
  faEdit = faPen;
  faTrashCan = faTrash;

  defaultPipeClassPressureUnits = Constants.imperialPipeClassPressureUnits;
  defaultPipeClassTemperatureUnits = Constants.imperialPipeClassTemperatureUnits;
  secondPipeClassPressureUnits: string = undefined;
  secondPipeClassTemperatureUnits: string = undefined;

  regexDigits = "^-?[0-9]*$";
  regexDigitsPositive = "^[0-9]*$";

  genericMissingValueMessage = "This is a required field";
  incompleteCellsMessage = "Cells must be fully populated before submitting";
  editTableDisplayedColumns: string[] = ['temperature', 'pressure'];
  readOnlyTableDisplayedColumns: string[] = ['temperatureF', 'pressurePSIG','temperatureC', 'pressureKPAG'];
  title: string;

  areCellsInMetric = false;
  validTableName = false;
  validTestNote = false;

  commonNotesAll: CommonNoteViewModel[];
  commonNotesSelected: CommonNoteViewModel[];
  commonNotesFiltered: CommonNoteViewModel[];

  commonNotesSelectedIds: string[];

  pressureTemperatureTable = new PressureTemperatureTableViewModel();
  updatedTable: PressureTemperatureTableViewModel;

  isMetric = false;
  isCreateMode = false;

  myForm: FormGroup;
  readOnlyDatasource: MatTableDataSource<{temperatureF: string, pressurePSIG: string, temperatureC: string, pressureKPAG: string}>;
  
  get pressureTemperatureCells(): FormArray {
    if (this.myForm.get('pressureTemperatureCells')) {
      return this.myForm.get('pressureTemperatureCells') as FormArray;
    }
  }

   ngOnInit(): void {
    this.getStoredPressureTemperatureTable();
    this.setTitle();
    this.initializeUpdatedTable();
    this.populateCommonNotes();
    this.initForm();
    if (this.pressureTemperatureTable.cells) {
      this.sortTableCellsByTemp(this.pressureTemperatureTable.cells);
    }
  }

  private getStoredPressureTemperatureTable(): void {
    const storedPressureTemperatureTable = localStorage.getItem('pressureTemperatureTable');
    if (storedPressureTemperatureTable) {
      this.pressureTemperatureTable = JSON.parse(storedPressureTemperatureTable);
      localStorage.removeItem('pressureTemperatureTable');
      this.isCreateMode = false;
    } else {
      this.isCreateMode = true;
    }

  }

  private setTitle() {
    if (this.isCreateMode) {
      this.title = 'Create a new pressure temperature table';
    } else {
      this.title = 'Edit pressure and temperature table';
    }
  }

  private initForm() {    
    let items = [];
    if (this.pressureTemperatureTable.cells && this.pressureTemperatureTable.cells.length > 0) {
      this.setupUnits();
      items = this.initializeEditTable(this.pressureTemperatureTable.cells);
      if (this.secondPipeClassPressureUnits !== undefined && this.secondPipeClassPressureUnits !== '') {
        this.readOnlyDatasource = this.initializeReadOnlyTable(this.pressureTemperatureTable.cells);
      }
    } else {
      items.push(this.formBuilder.group({
        temperature: ['', [Validators.required, Validators.pattern(this.regexDigits)]],
        pressure: ['', [Validators.required, Validators.pattern(this.regexDigitsPositive)]]
      }));
    }
    
    this.myForm = this.formBuilder.group({
      pressureTemperatureCells: this.formBuilder.array(items),
      tableName: new UntypedFormControl(this.pressureTemperatureTable.name, [
        Validators.required,
      ]),
      testMethod: new UntypedFormControl(''),
      addNote: new UntypedFormControl(''),
    });

    if (this.pressureTemperatureTable.relationNotes !== undefined) {
      this.myForm.get('testMethod').setValue(this.getNoteByTypeFromViewModel('PtrNote'));
      this.myForm.get('addNote').setValue(this.getNoteByTypeFromViewModel('Additional'));
    }

  }

  private getNoteByTypeFromViewModel(type: string): string {
    const noteArray = this.pressureTemperatureTable.relationNotes.filter(note => note.type === type);
    return noteArray[0].note;
  }

  private setupUnits() {
    const pressureUnits = this.pressureTemperatureTable.cells.map(cell => cell.pressureUnit).filter(uniqueFilter);
    // Legacy check - We check if we have values for more than one unit pair
    if (pressureUnits.length > 1) {
      pressureUnits.splice(pressureUnits.indexOf(this.defaultPipeClassPressureUnits), 1);
      if (pressureUnits.length != 0) {
        this.secondPipeClassPressureUnits = pressureUnits[0];
        const temperatureUnits = this.pressureTemperatureTable.cells.map(cell => cell.temperatureUnit).filter(uniqueFilter);
        temperatureUnits.splice(pressureUnits.indexOf(this.defaultPipeClassTemperatureUnits), 1);
        this.secondPipeClassTemperatureUnits = temperatureUnits[0];
      }
    } else {
      if (this.defaultPipeClassPressureUnits != pressureUnits[0]) {
        this.areCellsInMetric = true;
      }
      this.defaultPipeClassPressureUnits = pressureUnits[0];
      this.defaultPipeClassTemperatureUnits = this.pressureTemperatureTable.cells.map(cell => cell.temperatureUnit).filter(uniqueFilter)[0];
    }
  }

  private initializeEditTable(cells: PressureTemperatureCellViewModel[] ): FormGroup[]{
    const ptTablesUnits = this.filterOnlySpecificPressureUnits(cells, this.defaultPipeClassPressureUnits);

    const formCells: FormGroup[]  = [] 

    for (const ptCell of ptTablesUnits) {
      const item = this.formBuilder.group({
        temperature: [ptCell.temperature, [Validators.required, Validators.pattern(this.regexDigits)]],
        pressure: [ptCell.pressure, [Validators.required, Validators.pattern(this.regexDigitsPositive)]],
      });
      formCells.push(item);
    }

    return formCells;
  }

  private initializeReadOnlyTable(cells: PressureTemperatureCellViewModel[] ): MatTableDataSource <{temperatureF: string, pressurePSIG:string, temperatureC:string, pressureKPAG: string}> {
    const ptTablesUnits1 = this.filterOnlySpecificPressureUnits(cells, this.defaultPipeClassPressureUnits);
    const ptTablesUnits2 = this.filterOnlySpecificPressureUnits(cells, this.secondPipeClassPressureUnits);

    const array = [] 

    for (let i = 0; i< ptTablesUnits1.length; i++) {
      const item = this.formBuilder.group({
        temperatureF: [ptTablesUnits1[i].temperature],
        pressurePSIG: [ptTablesUnits1[i].pressure],
        temperatureC: [ptTablesUnits2[i].temperature],
        pressureKPAG: [ptTablesUnits2[i].pressure],
      });
      array.push(item);
    }

    return new MatTableDataSource(array);
  }

  initializeUpdatedTable(): void {
    this.updatedTable = new PressureTemperatureTableViewModel(
      this.pressureTemperatureTable
    );
  }

  //Possibly to be extracted as pressure-temp-table.component has same methods
  filterOnlySpecificPressureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.pressureUnit.toLowerCase() == unit.toLowerCase());
  }

  filterOnlySpecificTemperatureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.temperatureUnit.toLowerCase() == unit.toLowerCase());
  }

  /**
   * This function sorts all cells in each pressureTemperatureTable in ascending order
   */
  private sortTableCellsByTemp(tableToSort: PressureTemperatureCellViewModel[] ): void {
    tableToSort.sort((a, b) => {
      if (a.temperature > b.temperature) {
        return 1;
      }
      if (a.temperature < b.temperature) {
        return -1;
      }
      return 0;
    });
  }

  setMetricBool(event: MatSlideToggleChange): void {
    this.areCellsInMetric = event.checked;
    this.snackBarService.showWarning('You are changing units remember to recalculate pressure and temperature values');
  }

  populateCommonNotes(): void {
    if (this.pressureTemperatureTable?.commonNotes) {
      this.commonNotesSelected = this.pressureTemperatureTable.commonNotes;
      this.commonNotesSelectedIds = this.pressureTemperatureTable.commonNotes
        .map(cn => cn.noteId);
    } else {
      this.commonNotesSelected = [];
      this.commonNotesSelectedIds = [];
    }

    this.esraApiClient.getAllCommonNotes().subscribe((response) => {
       /**
       * Since there is multiple CommonNotes with same Code, the idea is to sort by ID and distinct
       * so distinct items have same NoteID so we will be using same instance of CommonNote
       *  
       */
      response.sort((a, b) => {
        if (a.noteId > b.noteId) {
          return 1;
        }
        if (a.noteId < b.noteId) {
          return -1;
        }
        return 0;
      });

      response = [...new Map(response.map(item =>
        [item['codeId'], item])).values()];

      response.sort((a, b) => {
        if (a.noteId > b.noteId) {
          return 1;
        }
        if (a.noteId < b.noteId) {
          return -1;
        }
        return 0;
      });
      this.commonNotesAll = response;
      this.commonNotesFiltered = this.commonNotesAll;
    });
  }

  onKeyCommonNotes(event): void {
    this.commonNotesFiltered = this.searchCommonNotesAll(event);
  }

  searchCommonNotesAll(value: string): CommonNoteViewModel[] {
    const filter = value.toLowerCase();
    return this.commonNotesAll.filter(
      (option) =>
        option.note.toLowerCase().includes(filter) ||
        option.codeId.toLowerCase().startsWith(filter)
    );
  }

  selectCommonNotes(event) {
    const eventValueHolder: string | undefined = String(event.value);
    const selectedNotesGUIDArr = eventValueHolder.split(',');
    
    this.commonNotesSelected = this.commonNotesSelected.filter(commonNote => selectedNotesGUIDArr.includes(commonNote.noteId));
    const commonNotesSelectedIds = this.commonNotesSelected.map(commonNote => commonNote.noteId);

    selectedNotesGUIDArr.filter(guid =>  !commonNotesSelectedIds.includes(guid))
      .map(guid => this.getCommonNoteVMById(guid))
      .filter(commonNote => commonNote !== undefined && commonNote !== null)
      .forEach(commonNote => this.commonNotesSelected.push(commonNote));
}

  getCommonNoteVMById(id: string): CommonNoteViewModel {
    const filteredArr = this.commonNotesAll.filter(
      (option) => option.noteId === id
    );
    return filteredArr[0];
  }

  removeSelectedNote(note: CommonNoteViewModel): void {
    this.commonNotesSelected.forEach((item, index) => {
      if (item.noteId === note.noteId) {
        this.commonNotesSelected.splice(index, 1);
      }
    });
    this.commonNotesSelectedIds = this.commonNotesSelectedIds.filter(noteId => noteId != note.noteId);
  }

  updateTable(): void {
    this.pressureTemperatureTable.name = this.myForm?.get('tableName')?.value;
    this.pressureTemperatureTable.commonNotes = this.commonNotesSelected;
    this.pressureTemperatureTable.additionalNotes = this.getAdditionalNote();
    this.pressureTemperatureTable.testNotes = this.getTestNote();
    this.addCellsToTable();

    if (this.myForm.valid && this.isCreateMode) {
      this.esraApiClient.createPressureTemperatureTable(this.pressureTemperatureTable)
        .subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true,
              `The pressure temperature table ${this.pressureTemperatureTable.name} was successfully saved`
            );
            this.router.navigate(['/piping/dictionaries/pressureandtemperature']);
          },
          error: (err) => {
            this.snackBarService.showSnackBar(
              true,
              `Error: ${err.message}`
            );
          }
        });
    }

    if (this.myForm.valid && !this.isCreateMode) {
      this.esraApiClient.updatePressureTemperatureTable(this.pressureTemperatureTable)
        .subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true,
              `The pressure temperature table ${this.pressureTemperatureTable.name} was successfully updated`
            );
            this.router.navigate(['/piping/dictionaries/pressureandtemperature']);
          },
          error: (err) => {
            this.snackBarService.showSnackBar(
              true,
              `Error: ${err.message}`
            );
          }
        });
    }

    if (this.myForm.invalid) {
      this.myForm.markAllAsTouched();
    }
  }

  addRowToTable(): void {
    const ptCells = this.pressureTemperatureCells;
    const item = this.formBuilder.group({
      temperature: ["", [Validators.required, Validators.pattern(this.regexDigits)]],
      pressure: ["", [Validators.required, Validators.pattern(this.regexDigitsPositive)]],
    });
    ptCells.push(item);
    this.changeDetectorRef.detectChanges();
  }
  
  removeRowFromTable(index: number): void {
    this.pressureTemperatureCells.removeAt(index);
  }

  addCellsToTable(): void {
    const newCellArr = [];
    for (const cell of this.pressureTemperatureCells.controls) {
      const newCell = new PressureTemperatureCellViewModel();
      newCell.pressure = cell?.get('pressure')?.value;
      newCell.temperature = cell?.get('temperature')?.value;
      newCell.temperatureUnit = this.areCellsInMetric ? Constants.metricPipeClassTemperatureUnits : Constants.imperialPipeClassTemperatureUnits;
      newCell.pressureUnit = this.areCellsInMetric ? Constants.metricPipeClassPressureUnits : Constants.imperialPipeClassPressureUnits;
      newCellArr.push(newCell);
    }
    this.pressureTemperatureTable.cells = newCellArr;
  }

  getAdditionalNote(): PressureTemperatureTableNote[] {
    const returnArr = [];
    const pttableAdditionalNote = new PressureTemperatureTableNote();
    if (this.myForm?.get('addNote')?.value != null) {
      pttableAdditionalNote.note =
        this.myForm?.get('addNote')?.value;
      pttableAdditionalNote.type = 0;
      returnArr.push(pttableAdditionalNote);
      return returnArr;
    }
  }

  getTestNote(): PressureTemperatureTableNote[] {
    const returnArr = [];
    const pttableTestMethodNote = new PressureTemperatureTableNote();
    if (this.myForm?.get('testMethod')?.value != null) {
      pttableTestMethodNote.note =
        this.myForm?.get('testMethod')?.value;
      pttableTestMethodNote.type = NoteType._1;
      returnArr.push(pttableTestMethodNote);
      return returnArr;
    }
  }

  closeWindow() {
    window.close();
  }
}
